

.example-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .next,
  .prev {
    top: calc(50% - 20px);
    position: absolute;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    z-index: 2;
  }
  
  .next {
    right: 10px;
  }
  
  .prev {
    left: 10px;
    transform: scale(-1);
  }
  

  
  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  



  


.circle{
  border-radius:50%;
  border-color:transparent;
  border-style: solid;
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  animation-name: circleAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.monifireworks span{
  display: block;
  width: 4px;
  height:200px;
  background: transparent;
  border-radius: 2px;
  position: absolute;
  top:50%;
  left:50%;
  overflow:hidden;
  

}

.monifireworks span:before, .monifireworks span:after{
  content:"";
  width:4px;
  height:0%;
  background:white;
  display:block;
  position:absolute;
  border-radius:2px;
    
}

.monifireworks span:after{
  bottom:0;
  animation-name: spanAnimationAfter;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.monifireworks span:before{
  top:0;
    animation-name: spanAnimationBefore;
  animation-duration: 1s;
  animation-iteration-count: infinite;

}



.monifireworks span:nth-child(1){
  transform: translate(-50%,-50%) rotate(45deg);
}

.monifireworks span:nth-child(2){
  transform: translate(-50%,-50%) rotate(90deg);
}

.monifireworks span:nth-child(3){
  transform: translate(-50%,-50%) rotate(135deg);
}

.monifireworks span:nth-child(4){
  transform: translate(-50%,-50%) rotate(180deg);
}

.monifireworks span:nth-child(5){
  transform: translate(-50%,-50%) rotate(225deg);
}

.monifireworks span:nth-child(6){
  transform: translate(-50%,-50%) rotate(270deg);
}

.monifireworks span:nth-child(7){
  transform: translate(-50%,-50%) rotate(315deg);
}

.monifireworks span:nth-child(8){
  transform: translate(-50%,-50%) rotate(0deg);
}

@keyframes circleAnimation {
  0% {width:0;height:0;border-width:0;border-color:white;}
  30%{width:0;height:0;border-width:20px}
  100% {width:100px;height:100px;border-width:0px;border-color:white; }
}

@keyframes spanAnimationBefore {
  0% {height:0;top:40%}
  30%{height:10%;}
  100% {height:0;}
}

@keyframes spanAnimationAfter {
  0% {height:0;bottom:40%}
  30%{height:10%;}
  100% {height:0;}
}